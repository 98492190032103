


















































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import IngredientAdditiveEntity from "@/entities/ingredient-additive-entity";
import { help as PopoverText } from '@/lang/help/product-create';
import IngredientEntity from "@/entities/ingredient-entity";
import ProductEntity from "@/entities/product-entity";
import ProductItemEntity from "@/entities/product-item-entity";
import IngredientItemEntity, {IAmountItem} from "@/entities/ingredient-item-entity";
import IngredientRepository from "@/repositories/company/ingredient-repository";
import ProductRepository from "@/repositories/company/product-repository";
import {ICarryoverable} from "@/entities/concerns/carryover-handler";
import {CarryoverReasonDict} from '@/entities/carryover-entity';
import PreproductRepository from "@/repositories/company/preproduct-repository";
import {Route} from "vue-router";
import IconOpen from "@/components/Icons/IconOpen.vue";
import {getAdditivePurposeBySynonymId} from "@/repositories/master/additive-repository";
import {
  IRecipeComponent,
  IRecipeComponentItem,
  RecipeComponentItemUnitType,
  RecipeComponentItemUnitTypeLabel
} from '@/entities/interfaces/i-recipe-component';
import IconLockedSmall from "@/components/Icons/IconLockedSmall.vue";

export type TItemGroupItem = { key:string; id?:number; name:string; search: string; isHeading?: boolean; locked?: boolean; };

@Component({
  components: {IconLockedSmall, IconOpen}
})
export default class extends Vue {
  private PopoverText = PopoverText;
  private CarryoverReasonDict = CarryoverReasonDict;
  private RecipeComponentItemUnitType = RecipeComponentItemUnitType;

  @Prop({required: true}) private model!:ProductEntity|IngredientEntity;
  @Prop({required: true}) private rules!:any; //IngredientValidatorRules|ProductValidatorRules;
  @Prop({required: true}) private itemLabel!:string;
  @Prop({required: true}) private selection!:TItemGroupItem[];
  @Prop({required: false}) private validateOnInput?: (selecting: IRecipeComponent, original: IRecipeComponentItem) => boolean;

  private get isDev():boolean {
    return this.$route.query.dev === 'true';
  }
  private mounted() {
    if (this.isDev) {
      console.log(this.model.getAllCarryoversRecursive());
    }
  }

  private get isProduct() { return this.model instanceof ProductEntity; }

  private get items():IRecipeComponentItem[] {
    return this.model.items as IRecipeComponentItem[];
  }
  private row(scope: { row: IRecipeComponentItem }): IRecipeComponentItem {
    return scope.row;
  }
  private rowItem(scope: { row: IRecipeComponentItem }): IRecipeComponent {
    return scope.row.item!;
  }
  private option(item: TItemGroupItem): TItemGroupItem{
    return item;
  }

  private getItemKeysToExpand() {
    return this.items.filter(item => item.carryoverHandler.isCarryoverOpened).map(i => i.rowKey);
  }

  private addRow() {
    this.items.push(this.isProduct ?
      new ProductItemEntity({}, this.model as ProductEntity) :
      new IngredientItemEntity({}, this.model as IngredientEntity)
    );
  }
  private deleteRow(index: number) {
    this.model.items.splice(index, 1);
  }

  private async itemSelected(row:IRecipeComponentItem, selectedKey: string) {
    const selectedIsProduct = selectedKey.startsWith(ProductEntity.KEY_PREFIX);
    const selectedIsPreproduct = selectedKey.startsWith(IngredientEntity.KEY_PREFIX_PREPRODUCT);
    const cId = this.$auth.user.company.id;
    const repo = selectedIsProduct ? (new ProductRepository(cId)) :
      selectedIsPreproduct ? (new PreproductRepository(cId)) : (new IngredientRepository(cId));
    const isTypeSame = (selectedIsProduct && this.isProduct) || (!selectedIsProduct && !this.isProduct);

    this.$set(row, 'loading', true);
    try {
      const showError = () => {
        this.$confirm(
          this.$t('選択された項目の中に、現在編集中の項目が使用されているため、この項目は選択できません'),
          this.$t("この項目は選択できません"),
        {
          confirmButtonText: this.$t('はい'),
          showCancelButton: false,
        }).catch(() => {});
        return false;
      };

      const selected = this.selection.find(s => s.key === selectedKey)!;

      if (this.model.id && isTypeSame && selected.id === this.model.id) {
        return showError();
      }

      const found = await repo.findById(selected.id!) as IRecipeComponent;
      if (this.model.id && isTypeSame && found.getAllChildren().some((i:IRecipeComponent) => i.id === this.model.id)) {
        return showError();
      }

      if (this.validateOnInput && !this.validateOnInput(found, row)) {
        return;
      }

      row.item = found;
      this.$emit('change-item', row);
    } finally {
      this.$set(row, 'loading', false);
    }
  }

  private getPrependTextOfSelectedItem(row: IRecipeComponentItem) {
    if (!row.item) return '';
    if (row.item.isProduct) return '【' + this.$t('商品') + '】 ';
    if (row.getChildIngredient()!.isPreproduct) return '【' + this.$t('中間原材料') + '】 ';
    return '【' + this.$t('原材料') + '】 ';
  }

  // アレルゲン個別表記(x一括表記)の時に、
  // アレルゲン由来の添加物が選択されたらアラートを表示する
  private alertIfOriginAllergenOmittedAndSummarized(checked, item:ICarryoverable) {
    if (this.isProduct) {
      if((this.model as ProductEntity).productDisplaySetting.isAllergenSummarized) return; // 個別表記なら何もしない
    }

    if (!checked) return; // チェックが外された時はなにもしない
    if (!item.containsAllergen) return; // 選択された添加物が由来アレルゲンをもっていない場合はなにもしない

    this.showAlertOfAllergenCarryover();
  }

  public showAlertOfAllergenCarryover() {
    // TODO: LANG
    const text = "原材料の中に「アレルゲンを含む原材料」または「アレルゲン由来添加物」が含まれており、" +
      "その項目が「内容をキャリーオーバー・加工助剤などの理由で非表示にする」にチェックが入っています。<br><br>" +
      "この状態で、アレルギーの個別表示にすることは認められていません。該当の項目の非表示設定" +
      "のチェックを外すか、アレルギーの表示を「一括表示」にしてください。";

    this.$confirm(text, this.$t("アレルゲン由来の項目の省略"), {
      confirmButtonText: this.$t('閉じる'),
      showCancelButton: false,
      dangerouslyUseHTMLString: true
    }).catch(() => {});
  }

  private canBeSplit(item: IRecipeComponentItem) {
    const ing = item.getChildIngredient();
    return ing && (ing.isPreproduct || ing.ingredientDisplaySetting.isCompositeCanBeSplitted);
  }

  private containsCompositeAsSplittedColumnShown() {
    return this.model.items.some(this.canBeSplit);
  }
  private getInnerItemConcentrationRatio(needle:IAmountItem) {
    return this.model.findAmountRatioForIngredientOrderInTheRoot(needle, false) || 0;
  }
  private getAdditiveDisplayName(ia:IngredientAdditiveEntity): string {
    const name = ia.additiveSynonym!.synonym;

    if (ia.additivePurposeSynonymId) {
      const aps = getAdditivePurposeBySynonymId(ia.additive!, ia.additivePurposeSynonymId);
      return `${name}（${aps.synonym}）`;
    }
    return name;
  }

  private getRouteToItem(item: IRecipeComponentItem) {
    const params = { id: item.item!.id } as any;
    if (item.item!.isProduct) {
      return {name: 'product.edit', params };
    } else if ((item.item! as IngredientEntity).isPreproduct) {
      return {name: 'preproduct.edit', params };
    } else {
      return {name: 'ingredient.edit', params };
    }
  }

  private invisibleSettingAllOpening = false;
  private toggleInvisibleSetting() {
    this.invisibleSettingAllOpening = !this.invisibleSettingAllOpening;
    this.items.forEach(i => i.carryoverHandler.isCarryoverOpened = this.invisibleSettingAllOpening);
  }
  private get showToggleInvisible(): boolean {
    return this.model.items.some(i => !!i.item && !i.item.isProduct);
  }

  private onUnitSelected(val: RecipeComponentItemUnitTypeLabel | number, row: IRecipeComponentItem) {
    if (typeof val === "number") {
      const iu = row.getChildIngredient()!.ingredientUnits.find(iu => iu.id === val)!;
      row.setIngredientUnit(iu);
    }
  }
}
