




















































































































import { Component, Prop, Watch} from 'vue-property-decorator';

import ProductRepository, {ProductForIndex, ProductListType} from "@/repositories/company/product-repository";

import InputSearch from '@/views/label/companies/components/InputSearch.vue';

import ProductEntity from "@/entities/product-entity";
import ReportRepository from "@/repositories/company/report-repository";
import LoadingHandler, {execWithLoading} from '@/utils/loading-handler';
import {extractFileNameFromResponseHeader, IFindOption} from "@/repositories/repository-base";
import ListPageBase from "@/views/label/companies/ListPageBase";
import stringifyCsv from "csv-stringify/lib/es5";
import * as FileSaver from "file-saver";
import round from 'lodash/round';
import uniq from 'lodash/uniq';
import ProductDisplayService from "@/services/product-display-service";
import formatDate from "date-fns/format";
import {BOM} from "@/utils/bom-utils";
import DeleteConfirmDialog from "@/views/label/companies/product/components/DeleteConfirmDialog.vue";
import DepartmentAnnotationOnIndex from "@/views/components/Department/DepartmentAnnotationOnIndex.vue";
import CompanyEntity from "@/entities/company-entity";
import EditableSlot from "@/views/components/Department/EditableSlot.vue";
import {CompanyRepository} from "@/repositories/company-repository";
import {
  CompanyCustomExportTemplateRepository
} from "@/repositories/company/company-custom-export-template-repository";
import {CustomExportTemplateEntity, CustomExportTemplateResourceType} from "@/entities/custom-export-template-entity";

@Component({
  components: {
    EditableSlot,
    DepartmentAnnotationOnIndex,
    DeleteConfirmDialog,
    InputSearch
  }
})
export default class extends ListPageBase {
  private readonly MAX_PRINTABLE_COUNT = 5;
  private readonly MAX_PRINTABLE_COUNT_ALLERGEN = 25;

  protected fetchIngredientCount:boolean = true;
  private deletionConfirmationModalVisible = false;

  private templates:CustomExportTemplateEntity[] = null as any;

  protected getRepository() {
    return new ProductRepository(this.companyId);
  }

  protected async find(searchText, opt:IFindOption) {
    return await (new ProductRepository(this.companyId)).search(searchText, opt);
  }

  protected async onCreating() {
    this.routeName = 'product';

    (new CompanyCustomExportTemplateRepository(this.companyId)).all(CustomExportTemplateResourceType.Product).then(list => {
      this.templates = list;
    });
  }

  private row(scope: { row: ProductForIndex }): ProductForIndex {
    return scope.row;
  }

  private async downloadLabelOrderEvidence() {
    if (this.selectedRows.length > this.MAX_PRINTABLE_COUNT_ALLERGEN) return;

    const findAllPromises = this.selectedRows.map((row:ProductForIndex) => this.getRepository().findById(row.id) as Promise<ProductEntity>);
    const products = await LoadingHandler(() => Promise.all(findAllPromises), 60000, {text: this.$t('ダウンロード中...') });
    LoadingHandler(() => {
      return (new ProductRepository(this.companyId)).downloadLabelOrderEvidence(products, this.company);
    }, 60000, {text: this.$t('ダウンロード中...') });
  }
  private downloadNutritionEvidenceSimple() {
    LoadingHandler(async () => {
      const rowIds = this.selectedRows.map((r:ProductForIndex) => r.id);
      return (new ProductRepository(this.companyId)).downloadNutritionEvidenceSimple(rowIds);
    }, 60000);
  }

  private downloadNutritionEvidenceDetail() {
    LoadingHandler(async () => {
      const rowIds = this.selectedRows.map((r:ProductForIndex) => r.id);
      return (new ProductRepository(this.companyId)).downloadNutritionEvidenceDetail(rowIds);
    }, 60000);
  }

  private downloadAllergenList() {
    LoadingHandler(async () => {
      const rowIds = this.selectedRows.map((r:ProductForIndex) => r.id);
      return (new ProductRepository(this.companyId)).downloadAllergenList(rowIds);
    }, 60000);
  }

  private downloadDepartmentReport() {
    if (this.selectedRows.length > this.MAX_PRINTABLE_COUNT) return;

    const promises = this.selectedRows.map((row:ProductForIndex) => {
      return this.getRepository().findById(row.id!).then(product => {
        return (new ProductRepository(this.companyId)).downloadDepartmentReport(product, this.company!).then(res => {
          return {name: row.name, response: res};
        });
      });
    });

    LoadingHandler(() => Promise.all(promises), 60000, {text: this.$t('ダウンロード中...') })
      .then((files:any) => {
        files.forEach(file => {
          ReportRepository.saveDownloadedBlob(file.response, `${file.name}_百貨店向け原材料等チェックシート`);
        });
      });
  }

  private downloadDepartmentAllergenReport() {
    if (this.selectedRows.length > this.MAX_PRINTABLE_COUNT_ALLERGEN) return;

    const findAllPromises = this.selectedRows.map((row:ProductForIndex) => this.getRepository().findById(row.id) as Promise<ProductEntity>);
    LoadingHandler(() => Promise.all(findAllPromises), 60000, {text: this.$t('ダウンロード中...') }).then((products:any[]) => {
      (new ReportRepository(this.companyId)).downloadDepartmentAllergenReport(products, this.company!).then(res => {
        ReportRepository.saveDownloadedBlob(res, this.$t(`催事アレルゲン表示確認・商品リスト`));
      });
    });
  }

  private exportCsv() {
    const findAllPromises = this.selectedRows.map((row:ProductForIndex) => this.getRepository().findById(row.id) as Promise<ProductEntity>);
    LoadingHandler(() => Promise.all(findAllPromises), 60000, {text: this.$t('ダウンロード中...')}).then((products:ProductEntity[]) => {
      const options = {
        header: true,
        columns: [
          '商品ID', '自社管理コード', '商品名（識別名）', '商品名（表示名）',
          '商品管理タグ',
          '名称', 'アレルゲン',
          '販売価格（税抜）', '販売価格（8％、切り下げ）', '販売価格（8％、切り上げ）', '販売価格（10％、切り下げ）', '販売価格（10％、切り上げ）',
          '原価（円）', '原価率（%）', '商品重量（g）',
          '原材料名', '添加物名',
          '商品アピール文',
          '100gあたり熱量(kcal)', '100gあたりたんぱく質(g)', '100gあたり脂質(g)', '100gあたり炭水化物(g)', '100gあたり食塩相当量(g)',
          '共有メモ', '最終更新日',
        ]
      };
      const rows = products.map((row: ProductEntity) => {
        return [
          row.companyOrd, row.intraCode, row.name, row.displayName,
          row.tags.join(','),
          row.commonName, uniq(row.getAllAllergens().map(a => a.getDisplayName())).join(','),
          row.price, row.getPriceWithTax(true, false), row.getPriceWithTax(true, true), row.getPriceWithTax(false, false), row.getPriceWithTax(false, true),
          row.costSumComputed, (row.costRatioComputed ? round(row.costRatioComputed * 100, 1) : '-'), row.getAmountSumGram(),
          new ProductDisplayService(row, row.productDisplaySetting, this.company.setting).getIngredientNamesSingle(),
          ProductDisplayService.getAdditiveNames(row, row.productDisplaySetting),
          row.appealText,
          row.getNutritionPer100g('calorie').formatForRaw(), row.getNutritionPer100g('protein').formatForRaw(),
          row.getNutritionPer100g('lipid').formatForRaw(), row.getNutritionPer100g('carb').formatForRaw(), row.getNutritionPer100g('salt').formatForRaw(),
          row.note, formatDate(row.updatedAt, 'yyyy-MM-dd'),
        ]
      });
      stringifyCsv(rows, options, (err, output) => {
        if (output) {
          FileSaver.saveAs(new Blob([BOM, output], {type: "text/plain;charset=utf-8" } ), "商品一覧.csv");
        }
        if (err) throw err;
      });

    });
  }

  public async onDeleting() {
    const hasReferredSpecs = this.selectedRows.filter((i: ProductForIndex) => i.referredSpecsCount > 0);
    if (hasReferredSpecs.length > 0) {
      this.deletionConfirmationModalVisible = true;
      return;
    } else {
      this.confirmDeleteSelected('id').then(() => {
        this.deleteSelectedProduct();
      });
    }
  }

  private async deleteSelectedProduct() {
    const idList = this.selectedRows.map((i:ProductListType) => i.id);

    // チェックトランザクション
    const latestData = await this.find(this.searchText, this.getSearchOpt());
    if(latestData.data.some(latest =>  {
      if (!idList.includes(latest.id)) return false;
      const target = (this.selectedRows as ProductForIndex[]).find(r => r.id === latest.id)!;
      return (target.referredSpecsCount === 0) !== (latest.referredSpecsCount === 0);
    })) {
      this.$message({type: 'error', message: this.$t('データが更新されています。画面をリロードして再度お試しください。')});
      return;
    }

    // 削除
    await LoadingHandler(async () => {
      await (new ProductRepository(this.companyId)).destroyBulk(idList);
      await this.load();
    });
    this.$message({type: 'info', message: this.$t('削除しました')});
  }

  private get canBulkImportRecipe(): boolean {
    // TODO
    return true;
  }

  private async exportCustom(t: CustomExportTemplateEntity) {
    const findAllPromises = this.selectedRows.map((row:ProductForIndex) => this.getRepository().findById(row.id) as Promise<ProductEntity>);
    const products = await LoadingHandler(() => Promise.all(findAllPromises), 60000, {text: this.$t('ダウンロード中...') });
    LoadingHandler(() => {
      return (new ProductRepository(this.companyId)).customExport(t.id, products, this.company);
    }, 60000, {text: this.$t('ダウンロード中...') });
  }

  private showAddingCustomExportTemplateDialog() {
    const msg =
      `<p class="u-color-gray-tertiary" style="letter-spacing: -0.05px;">こちらを設定することで、ワンクリックで設定したフォーマットに出力できます。</p>` +
      `<p class="u-mt24 u-color-danger u-bold" style="letter-spacing: -0.05px;">※ 現状のプランではご利用いただけません。画面右下のチャットからお問い合わせください。</p>`;
    this.$confirm2(msg, this.$t("オリジナルフォーマット出力\n（Excel、PDF、CSV）"), {
      dangerouslyUseHTMLString: true,
      customClass: 'center-button',
      confirmButtonText: this.$t('閉じる'),
      confirmButtonClass: 'c-button primary-inverse no-focus',
      showCancelButton: false,
    })
  }
}
